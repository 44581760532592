<script setup>
import { useStorage } from '@vueuse/core'

const echo = useLaravelEcho()

const checklists = useStorage('notificationChecklists', [])

echo.private(`employees.${user().id}`).listen('UpdateChecklistEvent', (e) => {
	const foundChecklist = checklists.value.find((item) => item.id == e.checklist.id)

	if (foundChecklist) return

	checklists.value.push({
		id: e.checklist.id,
		title: `${e.checklist.object.title} - ${e.checklist.title}`,
		subtitle: e.checklist.date,
		to: `/objects/${e.checklist.object.id}#checklist`,
	})
})
</script>

<template>
	<Notification
		icon="tabler-align-box-left-top"
		title="checklist.notifications"
		v-model="checklists"
		@removeAll="checklists = []" />
</template>
